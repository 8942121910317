@import 'assets/styles.scss';

.root {
  text-align: left;
}

.input {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.buttonContainer {
  margin-top: 20px;
  text-align: center;
}
