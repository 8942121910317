@import 'assets/styles.scss';

.root {
  text-align: center;
  padding: 0 20px;
}

.header {
  letter-spacing: 4px;
  color: $primary;
  text-align: center;
}

.header:after {
  color: $secondary;
  content: '.';
  font-size: 34px;
}

.subHeader {
  margin: 0;
  margin-top: 20px;
}

.confirmButton {
  margin-top: 20px;
}

@media only screen and (min-width: 0) and (max-width: 400px) {
  .confirmButton {
    margin-left: 10px;
  }
}
