@import 'assets/styles.scss';

.root {
  font-family: 'Raleway', sans-serif;
  letter-spacing: 1px;
  font-size: 13px;
  padding: 8px 15px;
  border: 1px solid $gray;
  color: $gray-dark;
  outline: none;
}

.root:focus {
  border-color: $secondary;
  color: $primary;
}

.root::selection {
  background-color: $secondary;
  color: $white;
}

.warning {
  border-color: $warning;
  margin-bottom: 0!important;
}

.validationMessage {
  margin: 5px 0 20px 0;
  color: $warning;
  font-size: 11px;
  font-family: 'Raleway', sans-serif;
}
