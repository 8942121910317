@import 'assets/styles.scss';

.root {
  position: fixed;
  display: flex;
  box-shadow: 0 0 3px rgba($black, 0.1);
  width: 100%;
  background-color: rgba($white, 0.95);
  z-index: 1000;
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  background-color: $white;
}

.secondary {
  box-shadow: none;
  background-color: transparent;
}

.innerContainer {
  position: relative;
  display: flex;
  margin: 0 100px 0 10%;
  padding: 10px;
  width: 100%;
}

.logo {
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  height: 30px;
  cursor: pointer;
}

.secondaryLogo {
  margin-top: 3px;
  height: 26px;
}

.menu {
  margin-left: auto;
  margin-right: 0;
}

.selectContainer {
  display: flex;
  margin-left: 30px;
}

.languageSelect {
  min-width: 110px;
  text-transform: uppercase;
}

.hamburgerMenu {
  position: fixed;
  right: 15px;
  top: 15px;
  display: none;
}

.hamburgerMenuItemAnchor {
  text-decoration: none;
}

.hamburgerMenuItemLabel {
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  cursor: pointer;
}

.hamburgerMenuItemLabel:hover {
  color: $secondary;
}

.hamburgerMenuSelects {
  margin-left: -10px;
}

@media only screen and (min-width: 0) and (max-width: 950px) {
  .hamburgerMenu {
    display: block;
  }

  .menu {
    display: none;
  }

  .selectContainer {
    display: none;
  }
}

@media only screen and (min-width: 0) and (max-width: 400px) {
  .innerContainer {
    margin: 0 1% 0 1%;
  }
  .selectContainer {
    margin-left: 15px;
  }
}

@media only screen and (min-width: 401px) and (max-width: 1250px) {
  .innerContainer {
    margin: 0 2% 0 2%;
  }
  .selectContainer {
    margin-left: 15px;
  }
}
