@import 'assets/styles.scss';

%toast-shared {
  padding: 10px;
  padding-left: 20px;
  border-radius: 2px;
}

.successToast {
  @extend %toast-shared;
  background-color: $secondary;
}

.infoToast {
  @extend %toast-shared;
  background-color: $white;
}

.warningToast {
  @extend %toast-shared;
  background-color: $white;
}

.errorToast {
  @extend %toast-shared;
  background-color: $error;
}

.container {
  display: flex;
  justify-content: center;
}

.text {
  color: $white;
}
