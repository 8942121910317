@import 'assets/styles.scss';

.root {
  display: flex;
  flex-wrap: wrap;
}

.header {
  letter-spacing: 4px;
  color: $primary;
  text-align: center;
}

.header:after {
  color: $secondary;
  content: '.';
  font-size: 34px;
}

.quote {
  width: calc(1 / 3 * 100% - (1 - 1 / 3) * 10px);
  min-width: 250px;
  margin: 0 auto;
  margin-top: 20px;
}

.informationInnerContainer {
  margin: auto;
  margin-bottom: 10px;
}

.subHeader {
  margin-bottom: 10px;
  margin-top: 0;
}

.backButton {
  border: 2px solid $black;
  background-color: $white;
  color: $black;
  margin-right: 5px;
  transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  margin-top: 10px;
}

.backButton:hover {
  background-color: $black;
  color: $white;
}
