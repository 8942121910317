@import 'assets/styles.scss';

.root {
  font-family: 'Raleway', sans-serif;
  text-decoration: none;
  display: inline-block;
}

.enabled {
  cursor: pointer;
}

.label {
  letter-spacing: 1px;
  margin: 0;
  color: $primary;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.label:hover {
  color: $secondary;
}

.disabled {
  opacity: 0.5;
  text-decoration: none !important;
  cursor: default !important;
}

.disabledLabel {
  letter-spacing: 1px;
  margin: 0;
  color: $primary;
}
