@import 'assets/styles.scss';

.root {
  text-align: left;
}

.header {
  margin-top: 0;
}

.registerContainer {
  display: flex;
}

.emailInput {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.passwordInput {
  width: 100%;
  box-sizing: border-box;
}

.validationMessageContainer {
  height: 40px;
  margin-top: 10px;
  text-align: center;
}

.validationMessage {
  color: $warning;
  margin: 0;
}

.buttonContainer {
  display: flex;
  margin-top: 15px;
}

.forgotPasswordLink {
  margin-right: 0;
  margin-left: auto;
  margin-top: 5px;
}
