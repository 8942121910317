@import 'assets/styles.scss';

.root {
}

.menuList {
  list-style-type: none;
  margin: 0;
}

.menuItemAnchor {
  text-decoration: none;
}

.menuItem {
  float: left;
  margin-right: 30px;
}

.menuItemText {
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  cursor: pointer;
}

.menuItemText:hover {
  color: $secondary;
}

@media only screen and (min-width: 0) and (max-width: 1100px) {
  .menuItem {
    margin-right: 15px;
  }
}
