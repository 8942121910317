@import 'assets/styles.scss';

.root {
  height: 5px;
}

.bar {
  opacity: 0;
  background-color: $secondary;
  height: 100%;
  -webkit-transition: width 0.3s; /* For Safari 3.1 to 6.0 */
  transition: width 0.3s;
  margin-top: -5px;
}

.backgroundBar {
  width: 100%;
  height: 100%;
  background-color: $gray;
}
