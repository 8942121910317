@import 'assets/styles.scss';

.header {
  letter-spacing: 4px;
  color: $primary;
  text-align: center;
}

.header:after {
  color: $secondary;
  content: '.';
  font-size: 34px;
}

.paragraph {
  margin-bottom: 10px;
}

.link {
  p {
    letter-spacing: 2px;
    font-weight: 600;
    font-size: 16px;
  }
}
