@import 'assets/styles.scss';

.root {
}

.header {
  letter-spacing: 4px;
  color: $primary;
  text-align: center;
}

.header:after {
  color: $secondary;
  content: '.';
  font-size: 34px;
}

.subHeader {
  text-align: center;
}

.translateFromContainer {
  margin-bottom: 20px;
}

.translateToContainer {
  margin-bottom: 20px;
}

.wordCountContainer {
  margin-bottom: 20px;
}

.wordCountInput {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.subjectContainer {
  margin-bottom: 20px;
}
