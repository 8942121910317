@import 'assets/styles.scss';

.root {
  font-family: 'Raleway', sans-serif;
  cursor: pointer;
  border-style: none;
  background-color: $primary;
  border-radius: 2px;
  padding: 11px 22px;
  font-size: 12px;
  letter-spacing: 2px;
  font-weight: 500;
  color: $white;
  transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
}

.root:focus {
  outline: none;
  box-shadow: 0 0 5px #000;
}

.root:hover {
  opacity: 0.85;
}

.secondary {
  background-color: $secondary;
  color: $white;
}

.secondary:focus {
  outline: none;
  box-shadow: 0 0 5px #000;
}

.transparent {
  padding: 8px 22px;
  background-color: transparent;
  color: $white;
  border: 2px solid $white;
}

.transparent:hover {
  background-color: $white;
  color: $primary;
  opacity: 1;
}

.transparent:focus {
  outline: none;
  box-shadow: 0 0 5px #000;
}

.white {
  background-color: $white;
  color: $primary;
}

.white:focus {
  outline: none;
  box-shadow: 0 0 5px #000;
}

.upperCase {
  text-transform: uppercase;
}

.disabled {
  opacity: 0.5;
  outline: 0;
}

.disabled:hover {
  opacity: 0.5 !important;
  outline: 0;
}

.disabled:hover {
  opacity: 0.7;
  cursor: default;
}

.bold {
  font-weight: 600;
}

.bolder {
  font-weight: 700;
}

.preIcon {
  margin-right: 7px;
}

.small {
  padding: 8px 10px;
}
