@import 'assets/styles.scss';

.root {
  overflow: auto;
}

.loader {
  margin-top: 10%;
}

.noMargin {
  margin: 0;
}

.filterContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.checkBoxContainer {
  margin-left: 10px;
  display: flex;
}

.checkBox {
  margin-right: 10px;
}

.checkBox:last-child {
  margin-right: 0;
}

.accountHeadersContainer {
  display: flex;
  margin-bottom: 10px;
}

.accountIdColumn {
  text-align: left;
  max-width: 50px;
  min-width: 50px;
  margin: 0 5px;
  display: flex;
  align-items: center;
}

.userNameColumn {
  text-align: left;
  max-width: 15%;
  min-width: 15%;
  overflow-wrap: break-word;
  word-break: break-all;
  margin: 0 5px;
  display: flex;
  align-items: center;
}

.companyNameColumn {
  text-align: left;
  max-width: 15%;
  min-width: 15%;
  overflow-wrap: break-word;
  margin: 0 5px;
  display: flex;
  align-items: center;
}

.organizationNumberColumn {
  text-align: left;
  max-width: 15%;
  min-width: 15%;
  overflow-wrap: break-word;
  margin: 0 5px;
  display: flex;
  align-items: center;
}

.customerNameColumn {
  text-align: left;
  max-width: 15%;
  min-width: 15%;
  overflow-wrap: break-word;
  margin: 0 5px;
  display: flex;
  align-items: center;
}

// .addressColumn {
//   text-align: left;
//   max-width: 10%;
//   min-width: 10%;
//   overflow-wrap: break-word;
//   margin: 0 5px;
//   display: flex;
//   align-items: center;
// }

// .addressColumnItem {
//   text-align: left;
//   max-width: 10%;
//   min-width: 10%;
//   overflow-wrap: break-word;
//   margin: 0 5px;
//   display: flex;
//   flex-direction: column;
//   p {
//     font-size: 11px;
//     line-height: unset;
//   }
// }

.phoneColumn {
  text-align: right;
  max-width: 150px;
  min-width: 150px;
  margin: 0 5px;
  display: flex;
  align-items: center;
}

// .enabledColumn {
//   text-align: right;
//   max-width: 150px;
//   min-width: 150px;
//   margin: 0 5px;
//   display: flex;
//   align-items: center;
// }

// .lockedColumn {
//   text-align: right;
//   max-width: 150px;
//   min-width: 150px;
//   margin: 0 5px;
//   display: flex;
//   align-items: center;
// }

.actionsColumn {
  text-align: right;
  width: 40%;
  margin: 0 5px;
}

.accountRowContainer {
  overflow: auto;
}

.accountRow {
  display: flex;
  align-items: center;
  padding: 10px 0;
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}

.accountRow:nth-child(even) {
  background: $gray-bright;
}

.accountRow:hover {
  background-color: $gray;
}

.noMargin {
  margin: 0;
}

.sortIcon {
  cursor: pointer;
  margin-left: 5px;
  width: 8px !important;
  color: $gray-dark;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  transition-property: transform;
}

.sortDecending {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.capitalize {
  text-transform: capitalize;
}

.headerLabel {
  text-transform: uppercase;
  color: $black;
  font-size: 13px;
}

.lockButton {
  margin: 5px;
  width: 110px;
}

.lockButton:focus {
  outline: none;
}

.unlockButton:focus {
  outline: none;
}

.unlockButton {
  margin: 5px;
  width: 110px;
}

.deleteButton {
  margin: 5px;
  width: 110px;
  background-color: $error;
}

.deleteButton:focus {
  outline: none;
}

.modalButtonContainer {
  display: flex;
  margin-top: 30px;
}

.modalCancelButton {
  margin-left: 0;
  margin-right: auto;
}

.modalCancelButton:focus {
  outline: none;
}

.modalDeleteButton {
  margin-right: 0;
  margin-left: auto;
  background-color: $error;
}

.modalDeleteButton:focus {
  outline: none;
}

.modalSaveFeaturesButton {
  margin-right: 0;
  margin-left: auto;
  background-color: $secondary;
}

.modalSaveFeaturesButton:focus {
  outline: none;
}

.accountVerifiedIcon {
  width: 15px !important;
  color: green;
  margin-left: auto;
  margin-bottom: 3px;
}
