@import 'assets/styles.scss';

.root {
}

.header {
  letter-spacing: 4px;
  color: $primary;
  text-align: center;
}

.header:after {
  color: $secondary;
  content: '.';
  font-size: 34px;
}

.loginAndRegisterContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.login {
  margin-top: 0;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  min-width: 260px;
  width: 40%;
}

.register {
  width: 40%;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  min-width: 260px;
}
