@import 'assets/styles.scss';

.root {
}

.header {
  letter-spacing: 4px;
  color: $primary;
  text-align: center;
}

.header:after {
  color: $secondary;
  content: '.';
  font-size: 34px;
}

.termsAndConditionsContainer {
  margin-top: 30px;
}
