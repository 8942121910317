@import 'assets/styles.scss';

.root {
  z-index: 500;
}

.barContainer {
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  cursor: pointer;
}

.barContainer:hover {
  opacity: 0.7;
}

%bar-shared {
  width: 30px;
  border-radius: 3px;
  margin-top: 6px;
  transition: 0.4s;
}

%bar-primary-shared {
  @extend %bar-shared;
  background-color: $black;
  border: 2px solid $black;
}

%bar-secondary-shared {
  @extend %bar-shared;
  background-color: $white;
  border: 2px solid $white;
}

.bar1_primary {
  @extend %bar-primary-shared;
  margin-top: 0;
}

.bar2_primary {
  @extend %bar-primary-shared;
}

.bar3_primary {
  @extend %bar-primary-shared;
}

.bar1_secondary {
  @extend %bar-secondary-shared;
  margin-top: 0;
}

.bar2_secondary {
  @extend %bar-secondary-shared;
}

.bar3_secondary {
  @extend %bar-secondary-shared;
}

.change .bar1_primary {
  -webkit-transform: rotate(-45deg) translate(-6px, 6px);
  transform: rotate(-45deg) translate(-6px, 6px);
}

.change .bar2_primary {
  opacity: 0;
}

.change .bar3_primary {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

.change .bar1_secondary {
  -webkit-transform: rotate(-45deg) translate(-9px, 4px);
  transform: rotate(-45deg) translate(-9px, 4px);
  background-color: $black;
  border: 2px solid $black;
}

.change .bar2_secondary {
  opacity: 0;
}

.change .bar3_secondary {
  -webkit-transform: rotate(45deg) translate(-9px, -5px);
  transform: rotate(45deg) translate(-9px, -5px);
  background-color: $black;
  border: 2px solid $black;
}

.menuContainer {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  background-color: white;
  width: 0;
  transition: width 0.25s ease;
  -moz-transition: width 0.25s ease;
  -webkit-transition: width 0.25s ease;
  -o-transition: width 0.25s ease;
  z-index: -1;
  overflow-y: auto;
  height: 100%;
}

.menuOpen {
  width: 300px;
}

.menuContentContainer {
  padding: 20px;
  margin-top: 50px;
}
