/* Global colors */
$primary: #323232;

$secondary-bright: #eed29f;
$secondary: #e6ae49;
$secondary-dark: #e29607;

$black: #000000;
$white: #ffffff;

$gray-brightest: #f7f7f7;
$gray-bright: #f1f1f1;
$gray: #dddddd;
$gray-dark: #888888;
$gray-darker: #555555;

$warning: #e67649;
$error: #e74c3c;
