@import 'assets/styles.scss';

:export {
  defaultColor: $gray-darker;
  pdfColor: #ff6a6a;
  excelColor: #079c02;
  wordColor: #748dfd;
  textColor: #5f5f5f;
}

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fileIcon {
}

.fileTypeText {
  margin: 0;
  font-size: 11px;
  color: $gray-darker;
  font-weight: 600;
  line-height: 1;
  margin-top: 5px;
}
