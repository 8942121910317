@import 'assets/styles.scss';

.root {
  overflow: auto;
  overflow-x: hidden;
}

.loader {
  margin-top: 10%;
}

.noMargin {
  margin: 0;
}

.filterContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.checkBoxContainer {
  margin-left: 10px;
  display: flex;
}

.checkBox {
  margin-right: 10px;
}

.checkBox:last-child {
  margin-right: 0;
}

.orderHeadersContainer {
  display: flex;
  margin-bottom: 10px;
}

.orderIdColumn {
  text-align: left;
  max-width: 5%;
  min-width: 5%;
  margin: 0 5px;
  display: flex;
  align-items: center;
  word-break: break-all;
}

.userNameColumn {
  text-align: left;
  max-width: 10%;
  min-width: 10%;
  overflow-wrap: break-word;
  margin: 0 5px;
  display: flex;
  align-items: center;
  word-break: break-all;
}

.dateHeader {
  text-align: left;
  max-width: 7%;
  min-width: 7%;
  margin: 0 5px;
  display: flex;
  align-items: center;
}

.placedColumn {
  text-align: left;
  max-width: 7%;
  min-width: 7%;
  margin: 0 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.deliverColumn {
  text-align: left;
  max-width: 7%;
  min-width: 7%;
  margin: 0 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.statusColumn {
  text-align: left;
  max-width: 8%;
  min-width: 8%;
  overflow-wrap: break-word;
  margin: 0 5px;
  display: flex;
  align-items: center;
  word-break: break-all;
}

.sourceLanguageColumn {
  text-align: left;
  max-width: 15%;
  min-width: 15%;
  overflow-wrap: break-word;
  margin: 0 5px;
  display: flex;
  align-items: center;
  word-break: break-all;
}

.targetLanguagesColumn {
  text-align: left;
  max-width: 15%;
  min-width: 15%;
  overflow-wrap: break-word;
  margin: 0 5px;
  display: flex;
  align-items: center;
  word-break: break-all;
}

.wordCountColumn {
  text-align: right;
  max-width: 5%;
  min-width: 5%;
  margin: 0 5px;
  display: flex;
  align-items: center;
  word-break: break-all;
}

.totalAmountColumn {
  text-align: right;
  max-width: 8%;
  min-width: 8%;
  margin: 0 5px;
  display: flex;
  align-items: center;
  word-break: break-all;
}

.actionsColumn {
  text-align: right;
  width: 20%;
  margin: auto 5px auto 5px;
}

.headerLabel {
  text-transform: uppercase;
  color: $black;
  font-size: 13px;
}

.orderRowContainer {
  overflow: auto;
}

.orderRow {
  display: flex;
  align-items: center;
  padding: 10px 0;
  transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -o-transition: all 0.25s ease 0s;
}

.orderRow:nth-child(even) {
  background: $gray-bright;
}

.orderRow:hover {
  background-color: $gray;
}

.noMargin {
  margin: 0;
}

.orderId {
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -o-transition: all 0.25s ease 0s;
}

.orderId:hover {
  color: $secondary;
}

.approveButton {
  margin-left: 10px;
  margin-top: 1px;
  margin-bottom: 1px;
}

.rejectButton {
  margin-left: 10px;
  margin-top: 1px;
  margin-bottom: 1px;
}

.connectButton {
  margin-left: 10px;
  margin-top: 1px;
  margin-bottom: 1px;
}

.sortIcon {
  cursor: pointer;
  margin-left: 5px;
  width: 8px !important;
  color: $gray-dark;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  transition-property: transform;
}

.sortDecending {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.capitalize {
  text-transform: capitalize;
}

.connectProjectModal {
  max-height: 100% !important;
  max-width: 500px !important;
  width: 100%;
  padding: 0 20px;
}

.connectProjectModalInputContainer {
  display: flex;
  flex-direction: column;
}

.connectProjectModalButtonContainer {
  display: flex;
  margin-top: 30px;
}

.connectProjectModalCancelButton {
  margin-left: 0;
  margin-right: auto;
}

.connectProjectModalCancelButton:focus {
  outline: none;
}

.connectProjectModalProjectNumberInput {
  flex: 1;
}
