@import 'assets/styles.scss';

:export {
  grayBrightColor: $gray-bright;
  grayColor: $gray;
}

.root {
  max-width: 350px;
}

.header {
  margin: 0;
}

.errorLabel {
  font-size: 20px;
  color: $primary;
  letter-spacing: 1px;
  margin: 0;
}

.currencyLabel {
  margin-top: 0;
  text-transform: uppercase;
  color: $primary;
  letter-spacing: 1px;
}

.priceLabel {
  font-size: 50px;
  color: $primary;
  letter-spacing: 1px;
  margin: 20px;
  margin-bottom: 10px;
  margin-top: 13px;
}

.deliveryContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.daysUntilDeliveryContainer {
  display: inline-flex;
}

.deliveryLabel {
  margin-bottom: 5px;
}

.deliveryDaysLabel {
  font-size: 30px;
  color: $primary;
  letter-spacing: 2px;
  margin: 0;
  margin-bottom: 0;
}

.numberOfDeliveryDaysLabel {
  margin-right: 10px;
}

.dateDeliveryContainer {
  display: inline-flex;
}

.dateLabel {
  margin-top: 5px;
  margin-bottom: 0;
  margin-right: 5px;
}

.cetLabel {
  margin-top: 5px;
  margin-bottom: 0;
}

.wordsLabel {
  letter-spacing: 1px;
  font-weight: 400;
}

.loginButton {
  display: block;
  margin: 5px auto;
}

.registerButton {
  display: block;
  margin: 5px auto;
}

.quoteDescriptionLabel:last-child {
  margin: 0;
}

.redColor {
  color: $error;
}
