@import 'assets/styles.scss';

:export {
  transparent: transparent;
}

.root {
  margin-left: 10%;
  margin-right: 10%;
}

.header {
  margin-top: 0;
  letter-spacing: 4px;
  color: $primary;
  text-align: center;
}

.header:after {
  color: $secondary;
  content: '.';
  font-size: 34px;
}

.collapsible {
  padding: 20px;
  border: 1px solid $gray-bright;
  border-bottom: none;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.collapsible:last-child {
  border-bottom: 1px solid $gray-bright;
}

.collapsibleTrigger {
  cursor: pointer;
  white-space: pre-line;
  display: block;
  color: $primary;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2px;
  line-height: 17px;
  font-weight: 600 !important;
  margin: 0;
  font-weight: 600;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.collapsibleTrigger:after {
  float: right;
  font-size: 15px;
  font-weight: 100;
  content: '^';
  color: $gray;
  -webkit-transform: scale(1.5, 1) rotate(180deg); /* Safari and Chrome */
  -moz-transform: scale(1.5, 1) rotate(180deg); /* Firefox */
  -ms-transform: scale(1.5, 1) rotate(180deg); /* IE 9 */
  -o-transform: scale(1.5, 1) rotate(180deg); /* Opera */
  transform: scale(1.5, 1) rotate(180deg); /* W3C */
  transition: transform 300ms;
}

.collapsibleTriggerOpen {
  cursor: pointer;
  white-space: pre-line;
  display: block;
  color: $secondary;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2px;
  line-height: 17px;
  font-weight: 600 !important;
  margin: 0;
  font-weight: 600;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.collapsibleTriggerOpen:after {
  float: right;
  font-size: 15px;
  font-weight: 100;
  content: '^';
  color: $gray;
  -webkit-transform: scale(1.5, 1); /* Safari and Chrome */
  -moz-transform: scale(1.5, 1); /* Firefox */
  -ms-transform: scale(1.5, 1); /* IE 9 */
  -o-transform: scale(1.5, 1); /* Opera */
  transform: scale(1.5, 1); /* W3C */
  transition: transform 300ms;
}

.collapsibleTrigger:hover {
  color: $secondary;
}

.contentOuter {
}

.link {
  letter-spacing: 1px;
  margin: 0;
  color: $primary;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.link:hover {
  color: $secondary;
}

.answerText {
  text-transform: initial;
  white-space: pre-line;
}

@media only screen and (min-width: 0) and (max-width: 400px) {
  .collapsible {
    padding: 10px;
  }
}

@media only screen and (min-width: 0) and (max-width: 600px) {
  .root {
    margin-left: 5%;
    margin-right: 5%;
  }
}
