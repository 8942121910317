@import 'assets/styles.scss';

.root {
  height: 100%;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/HomeBackground/home_background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-attachment: fixed;
  background-position: center center;
  z-index: -1;
}

.header {
  text-align: center;
}

.subHeader {
  letter-spacing: 15px;
  margin-bottom: 20px;
  padding-top: 10px;
  text-align: center;
}

.paragraph {
  text-align: center;
}

.buttonContainer {
  text-align: center;
  margin-top: 20px;
}

.outerContentContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.contentContainer {
  margin: auto;
  padding: 0 20px;
}

.bottomMargin {
  height: 10%;
}

.aboutLink {
  text-decoration: none;
  outline: none;
  background-color: transparent;
  cursor: default;
  transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -o-transition: all 0.25s ease;
}

.mouse {
  position: relative;
  display: block;
  cursor: pointer;
  width: 30px;
  height: 45px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid #fff;
  border-radius: 23px;
  -moz-border-radius: 23px;
  -webkit-border-radius: 23px;
  margin-left: auto;
  margin-right: auto;
}

.mouseDot {
  position: absolute;
  display: block;
  top: 29%;
  left: 50%;
  width: 6px;
  height: 6px;
  margin: -3px 0 0 -3px;
  background: $white;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -webkit-animation: ani-mouse 2.5s linear infinite;
  -moz-animation: ani-mouse 2.5s linear infinite;
  animation: ani-mouse 2.5s linear infinite;
}

.sectionRef {
  height: 100px;
}

.scrollToTopButton {
  height: 40px;
  width: 40px;
  background-color: $gray-darker;
  transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -o-transition: all 0.25s ease;
  color: $white;
  position: fixed;
  bottom: 20px;
  right: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.scrollToTopButton:hover {
  background-color: $secondary;
}

.scrollToTopButtonVisible {
  opacity: 1;
  cursor: pointer;
}

.scrollToTopIcon {
  font-size: 20px;
}

.footerContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid $gray-bright;
  padding-top: 40px;
  background-color: $gray-brightest;
  margin-top: 100px;
}

.footerContactContainer {
  padding-left: 25px;
  padding-right: 25px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  padding-bottom: 40px;
}

.footerLinkContainer {
  padding-left: 25px;
  padding-right: 25px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  padding: 60px 0;
}

.footerLogo {
  width: 125px;
  cursor: pointer;
}

.footerColumn {
  margin-right: 15%;
  margin-bottom: 20px;
}

.footerColumn:last-child {
  margin-right: 0;
}

.menuList {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menuItemText {
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  cursor: pointer;
}

.menuItemText:hover {
  color: $secondary;
}

.noTopMargin {
  margin-top: 0;
}

.addressLabel {
  line-height: 1;
  color: $black;
}

.contactContainer {
  display: flex;
  flex-direction: column;
}

.contactIcon {
  width: 13px;
  margin-right: 5px;
}

.mailLink {
  color: $black;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  margin-top: 20px;
  font-weight: 600;
  font-size: 13px;
}

.mailLink:hover {
  color: $secondary;
}

.phoneLink {
  color: $black;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  margin-top: 10px;
  font-weight: 600;
  font-size: 13px;
}

.phoneLink:hover {
  color: $secondary;
}

.companyName {
  color: $black;
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  cursor: pointer;
  margin: 0;
}

.companyName:hover {
  color: $secondary;
}

.divider {
  border-top: 1px solid $gray-bright;
  margin: 0;
}

.serviceByLabel {
  margin: 0;
}

.faqSectionContainer {
  display: inline;
}

.hiddenLink {
  visibility: hidden;
  width: 0;
}

@media only screen and (min-width: 943px) and (max-width: 1200px) {
  .footerColumn {
    margin-right: 10%;
  }
}

@media only screen and (min-width: 0px) and (max-width: 942px) {
  .footerColumn {
    margin-right: 0;
    width: 100%;
  }

  .footerContactContainer {
    text-align: center;
  }

  .footerLogo {
    margin-left: auto;
    margin-right: auto;
  }
}

// @media only screen and (min-width: 943px) and (max-width: 1250px) {
//   .footerContactContainer {
//     padding-left: 150px;
//   }
// }

// @media only screen and (min-width: 490px) and (max-width: 899px) {
//   .footerContactContainer {
//     padding-left: 120px;
//   }
// }

// @media only screen and (min-width: 390px) and (max-width: 489px) {
//   .footerContactContainer {
//     padding-left: 90px;
//   }
// }

// @media only screen and (min-width: 280px) and (max-width: 389px) {
//   .footerContactContainer {
//     padding-left: 50px;
//   }
// }

// @media only screen and (min-width: 0px) and (max-width: 279px) {
//   .footerContactContainer {
//     padding-left: 15px;
//   }
// }

// @media only screen and (min-width: 0) and (max-width: 768px) {
//   .scrollToTopButton {
//     display: none;
//   }
// }

@media only screen and (min-width: 0) and (max-width: 1200px) {
  .sectionRef {
    height: 60px;
  }
}

@keyframes ani-mouse {
  0% {
    opacity: 1;
    top: 29%;
  }
  15% {
    opacity: 1;
    top: 50%;
  }
  50% {
    opacity: 0;
    top: 50%;
  }
  100% {
    opacity: 0;
    top: 29%;
  }
}
