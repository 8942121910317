@import 'assets/styles.scss';

:export {
  transparent: transparent;
}

.root {
  margin-left: 10%;
  margin-right: 10%;
}

.header {
  margin-top: 0;
  letter-spacing: 4px;
  color: $primary;
  text-align: center;
}

.header:after {
  color: $secondary;
  content: '.';
  font-size: 34px;
}

.bodyTextContainer {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.bodyText {
  letter-spacing: 1px;
}

.numberOfLanguagesText {
  font-weight: 700;
  letter-spacing: 1px;
}

.languageContainer {
  display: flex;
  flex-wrap: wrap;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 20px;
}

.languages {
  width: calc(1 / 8 * 100% - (1 - 1 / 8) * 10px);
  min-width: 150px;
  margin-top: 20px;
  padding: 0;
}

.languageLabel {
  color: $black;
  font-weight: 600;
}

.languageLabel:last-child {
  margin: 0;
}
