@import 'assets/styles.scss';

.root {
}

.header {
  letter-spacing: 4px;
  color: $primary;
  text-align: center;
}

.header:after {
  color: $secondary;
  content: '.';
  font-size: 34px;
}

.subHeader {
  margin: 0 0 10px 0;
}

.container {
  text-align: left;
}

.input {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.button {
  margin-top: 20px;
}

.timezoneLabel {
  margin-bottom: 0;
}

.timezoneSubLabel {
  margin-top: 0;
}
