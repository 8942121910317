@import 'assets/styles.scss';

.root {
  max-width: 90% !important;
  max-height: 85% !important;
  margin-top: 100px;
  width: 100%;
}

.orderDetailsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.orderContainer {
  background-color: $gray;
  padding: 10px;
  margin: 5px;
  flex: 1;
  min-width: 300px;
}

.accountContainer {
  background-color: $gray;
  padding: 10px;
  margin: 5px;
  flex: 1;
  min-width: 300px;
}

.filesContainer {
  background-color: $gray;
  padding: 10px;
  margin: 5px;
  flex: 1;
  min-width: 300px;
  display: flex;
  flex-direction: column;
}

.header {
  margin: 0;
  color: $black;
  margin-bottom: 20px;
}

.tableRow {
  text-align: left;
  vertical-align: top;
}

.labelCell {
  padding-right: 20px;
}

.label {
  margin: 0;
  color: $black;
}

.value {
  margin: 0;
  max-width: 300px;
  word-break: break-all;
}

.fileLinkContainer {
  display: flex;
}

.fileLink {
  display: inline-block;
  color: $black;
  cursor: pointer;
  word-break: break-all;
  text-decoration: underline;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  flex: 1;
}

.fileLink:hover {
  color: $secondary;
}

.fileSizeLabel {
  margin: 0;
  margin-left: 20px;
}

.removeDocumentIcon {
  margin-top: 6px;
  height: 13px;
  cursor: pointer;
  margin-left: 13px;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.hiddenRemoveDocumentIcon {
  margin-left: 13px;
  opacity: 0;
}

.removeDocumentIcon:hover {
  path {
    fill: $secondary;
  }
}

.deleteDocumentModal {
  max-height: 100% !important;
}

.modalButtonContainer {
  display: flex;
  margin-top: 30px;
}

.modalCancelButton {
  margin-left: 0;
  margin-right: auto;
}

.modalCancelButton:focus {
  outline: none;
}

.modalDeleteButton {
  margin-right: 0;
  margin-left: auto;
  background-color: $error;
}

.modalDeleteButton:focus {
  outline: none;
}

.modalChangeStatusButton {
  margin-right: 0;
  margin-left: auto;
  background-color: $secondary;
}

.modalChangeStatusButton:focus {
  outline: none;
}

.modalDeleteDocumentFileName {
  margin: 0;
}

.noMargin {
  margin: 0;
}

.addDocumentButton {
  margin-left: auto;
  margin-top: auto;
  margin-right: auto;
}

.allDocumentsDeletedLabel {
  color: $error;
}

.addDocumentModal {
  z-index: 2002;
  max-height: 100% !important;
}

.addDocumentModalBackdrop {
  z-index: 2001;
}

.addDocumentModalContainer {
  width: 500px;
  height: 460px;
  display: flex;
  flex-direction: column;
}

.addDocumentTranslationFileContainer {
  margin-top: 50px;
  text-align: left;
  display: flex;
}

.browseFileButton {
  max-height: 40px;
  margin-top: auto;
  margin-bottom: auto;
}

.translationFileLabel {
  margin-left: 20px;
}

.addDocumentModalButtonContainer {
  display: flex;
  flex: 1;
  align-items: flex-end;
}

.modalAddButton {
  margin-right: 0;
  margin-left: auto;
  background-color: $secondary;
}

.loader {
  margin-top: 50px;
}
