@import 'assets/styles.scss';

:export {
  primaryColor: $primary;
  secondaryColor: $secondary;
  secondaryBrightColor: $secondary-bright;
  whiteColor: $white;
  grayColor: $gray;
  darkGrayColor: $gray-dark;
}

.root {
  margin-right: 20px;
}
