@import 'assets/styles.scss';

%typography-shared {
  color: $primary;
  letter-spacing: 2px;
  font-weight: 300;
  > span {
    &::selection {
      background-color: $secondary;
      color: $white;
    }
  }
}

.h1 {
  @extend %typography-shared;
}

.h2 {
  @extend %typography-shared;
  font-size: 27px;
  line-height: 37px;
}

.h3 {
  @extend %typography-shared;
  font-size: 21px;
  line-height: 32px;
}

.h4 {
  @extend %typography-shared;
  font-size: 17px;
  line-height: 28px;
}

.h5 {
  @extend %typography-shared;
  font-size: 15px;
  line-height: 23px;
}

.s1 {
  @extend %typography-shared;
  font-size: 16px;
  line-height: 24px;
  color: $gray-dark;
}

.label {
  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 1px;
  margin-top: 6px;
  margin-bottom: 10px;
}

.b1 {
  @extend %typography-shared;
  font-size: 14px;
  line-height: 24px;
  color: $gray-dark;
}

.b2 {
  @extend %typography-shared;
  font-size: 12px;
  line-height: 24px;
  color: $gray-dark;
}

.b2 {
  @extend %typography-shared;
}

.bt {
  @extend %typography-shared;
  font-size: 12px;
}

.c {
  @extend %typography-shared;
  font-size: 13px;
  line-height: 24px;
  color: $gray-dark;
  font-weight: 400;
}

.secondary {
  color: $white;
}

.bold {
  font-weight: 600;
}

.bolder {
  font-weight: 700;
}

.disabled {
  opacity: 0.85;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.inline {
  display: inline;
}

.upperCase {
  text-transform: uppercase;
}
