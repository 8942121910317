@import 'assets/styles.scss';

:export {
  primaryColor: $primary;
  secondaryColor: $secondary;
  secondaryBrightColor: $secondary-bright;
  whiteColor: $white;
  grayColor: $gray;
  darkGrayColor: $gray-dark;
}

.root {
  font-family: 'Raleway', sans-serif;
  letter-spacing: 1px;
  font-size: 13px;
}

.root:focus {
  border-color: $secondary;
}

.root::selection {
  background-color: $secondary;
  color: $white;
}

.notValid {
  > div {
    border-color: $warning;
  }
}

.validationText {
  margin: 5px 0 20px 0;
  color: $warning;
  font-size: 11px;
  font-family: 'Raleway', sans-serif;
}

.selected {
  font-weight: 600;
}
