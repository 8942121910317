@import 'assets/styles.scss';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.loadingContainer {
  position: absolute;
  margin-top: 5%;
  display: none;
  opacity: 0;
}

.hide {
  display: none!important;
  opacity: 0!important;
}

.confirmationContainer {
  position: absolute;
  margin-top: 5%;
  display: none;
  opacity: 0;
}

.orderSummaryContainer {
  display: block;
  opacity: 1;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.loader {
  margin-top: 10%;
}

.header {
  letter-spacing: 4px;
  color: $primary;
  text-align: center;
}

.header:after {
  color: $secondary;
  content: '.';
  font-size: 34px;
}

.subHeader {
  margin-bottom: 10px;
  margin-top: 0;
}

.deliveryTextContainer {
  margin-top: 20px;
}

.instructionsLabelContainer {
  margin-top: 20px;
}

.instructionsLabel {
  margin: 0;
}

.instructionsTextArea {
  margin-top: 10px;
  height: 80px;
}

.referenceContainer {
  margin-top: 20px;
}

.referenceLabel {
  margin: 0;
}

.referenceInput {
  margin-top: 10px;
}

.termsCheckBoxRoot {
  display: inline-block;
}

.termsCheckBox {
  vertical-align: unset;
}

.termsContainer {
  margin-top: 20px;
}

.termsLabel {
  margin: 0;
  margin-left: 5px;
  vertical-align: text-bottom;
}

.termsLink {
  vertical-align: bottom;
}

.termsAndConditionsModal {
  max-width: 85% !important;
  max-height: 70% !important;
}

.termsAndConditionsModalContentContainer {
  text-align: center;
}

.termsAndConditionsModalTextContainer {
  max-height: 400px;
  overflow-y: auto;
}

.termsAndConditionsModalButton {
  margin-top: 30px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.backButton {
  border: 2px solid $black;
  background-color: $white;
  color: $black;
  margin-right: 5px;
  transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
}

.backButton:hover {
  background-color: $black;
  color: $white;
}

.nextButton {
  margin-left: 5px;
}
