@import 'assets/styles.scss';

.root {
  text-align: left;
}

.header {
  margin-top: 0;
}

.instructionsText {
  line-height: 20px;
  font-weight: 300;
  margin: 0;
  letter-spacing: 1px;
  display: inline-block;
  font-size: 13px;
  margin-bottom: 10px;
}

.emailInput {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.bodyText {
  line-height: 20px;
  font-weight: 300;
  margin: 0;
  letter-spacing: 1px;
  display: inline-block;
  font-size: 13px;
}

.link {
  margin-left: 5px;
}

.buttonContainer {
  margin-top: 20px;
}
