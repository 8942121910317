@import 'assets/styles.scss';

.root {
  position: fixed;
  background: $white;
  z-index: 2001;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  overflow-y: hidden;
  overflow-x: hidden;
  max-width: 0;
  max-height: 0;
}

.contentContainer {
  opacity: 0;
  padding: 20px;
  visibility: hidden;
}

.loadingContainer {
  opacity: 0;
  padding: 20px;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  visibility: hidden;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2000;
  background: $gray-dark;
  opacity: 0;
  display: none;
}

.title {
  margin-top: 0;
}

@media only screen and (min-width: 0) and (max-width: 700px) {
  .root {
    max-height: 90% !important;
    max-width: 90% !important;
    width: 90% !important;
  }
}
