@import 'assets/styles.scss';

.root {
}

.container {
  text-align: left;
}

.header {
  letter-spacing: 4px;
  color: $primary;
  text-align: center;
}

.header:after {
  color: $secondary;
  content: '.';
  font-size: 34px;
}

.subHeader {
  margin: 0 0 10px 0;
}

.input {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.countrySelectContainer {
  margin-bottom: 15px;
}

.checkBoxRoot {
  display: inline-block;
}

.checkBox {
  margin-right: 10px;
  vertical-align: sub;
}

.bodyText {
  line-height: 20px;
  font-weight: 300;
  margin: 0;
  letter-spacing: 1px;
  display: inline-block;
  font-size: 13px;
}

.link {
}

.validationText {
  display: none;
  position: absolute;
  margin: 0;
  color: $warning;
  font-size: 11px;
  font-family: 'Raleway', sans-serif;
}

.show {
  display: block;
}

.buttonContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.goBackButton {
  margin-top: 25px;
}

.registrationButton {
  margin-top: 25px;
}
