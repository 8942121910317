@import 'assets/styles.scss';

:export {
  transparent: transparent;
}

.root {
  margin-left: 10%;
  margin-right: 10%;
}

.header {
  margin-top: 0;
  letter-spacing: 4px;
  color: $primary;
  text-align: center;
}

.bodyText {
  text-align: center;
  letter-spacing: 1px;
  margin-bottom: 0;
}

.header:after {
  color: $secondary;
  content: '.';
  font-size: 34px;
}

.servicesContainer {
  display: flex;
  flex-wrap: wrap;
}

.container {
  max-width: 350px;
}
