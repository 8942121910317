@import 'assets/styles.scss';

.root {
  text-align: left;
}

.header {
  margin-top: 0;
}

.bodyText {
  letter-spacing: 1px;
}

.emailInput {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.passwordInput {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.buttonContainer {
  display: flex;
}

.link {
  margin-right: 0;
  margin-left: auto;
}
