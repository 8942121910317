@import 'assets/styles.scss';

.root {
  display: flex;
}

.loader {
  margin-top: 10%;
}

.settingsContainer {
  text-align: left;
  flex: 1;
  margin-right: 10px;
  margin-top: 0;
  max-height: 500px;
  overflow: auto;
}

.settingsContainer:last-child {
  margin-right: auto;
}

.settingsHeader {
  margin-top: 0;
  margin-bottom: 10px;
}

.apiKeyTextArea {
  width: 100%;
  box-sizing: border-box;
  word-break: break-all;
  resize: none;
}

.input {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.settingsContainer .input:last-child {
  margin-bottom: 0;
}

.sandboxCheckBox {
  margin-bottom: 20px;
}

.saveButton {
  margin-top: 20px;
}
