@import 'assets/styles.scss';

.root {
}

.account {
  display: inline-block;
  width: 100%;
}

.accountDetails {
  display: none;
  opacity: 0;
  transform: translateX(50%);
}

.termsAndConditionsModal {
  max-width: 85% !important;
  max-height: 70% !important;
}

.termsAndConditionsModalContentContainer {
  text-align: center;
}

.termsAndConditionsModalTextContainer {
  max-height: 400px;
  overflow-y: auto;
}

// .termsAndConditionsModalButton {
//   margin-top: 30px;
// }
