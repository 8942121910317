@import 'assets/styles.scss';

.root {
  text-align: left;
}

.header {
  letter-spacing: 4px;
  color: $primary;
  text-align: center;
}

.header:after {
  color: $secondary;
  content: '.';
  font-size: 34px;
}

.subHeader {
  text-align: center;
}

.dropZoneContainer {
  display: inline-block;
}

.dragAndDropContainer {
  border: 1px dotted $gray;
  border-top: 1px solid $white;
}

.documentList {
  list-style: none;
  padding: 0;
}

.documentContainer {
  display: flex;
  padding: 0 50px;
  text-align: left;
}

.documentNameLabel {
  margin: 2px;
  word-break: break-all;
}

.documentSizeLabel {
  margin: 2px;
  display: inline-block;
}

.documentActionContainer {
  display: inline-block;
  margin-right: 0;
  margin-left: auto;
  min-width: 120px;
  text-align: right;
  margin-top: auto;
}

.removeDocumentIcon {
  height: 13px;
  cursor: pointer;
  margin-left: 5px;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.removeDocumentIcon:hover {
  path {
    fill: $secondary;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.backButton {
  border: 2px solid $black;
  background-color: $white;
  color: $black;
  margin-right: 5px;
  transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
}

.backButton:hover {
  background-color: $black;
  color: $white;
}

.backButtonNoHover {
  border: 2px solid $black;
  background-color: $white;
  color: $black;
  margin-right: 5px;
}

.nextButton {
  margin-left: 5px;
}

.loader {
  margin-top: 30px;
}

.uploadProgressBar {
  width: 100%;
  padding: 0 50px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
