@import 'assets/styles.scss';

.root {
  display: inline-block;
}

.loader {
  margin-top: 10%;
}

.orderContainer {
  margin-bottom: 20px;
  background-color: $gray-bright !important;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  border-color: transparent !important;
  transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
}

.orderContainer:hover {
  -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);
}

.orderContainer:last-child {
  margin-bottom: 0;
}

.orderHeaderContainer {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.headerLabel {
  margin: 0;
  margin-bottom: 10px;
}

.translationRow {
  display: flex;
  margin-bottom: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.translationRow:last-child {
  margin-bottom: 0;
}

.translationContainer {
  display: flex;
  max-width: 300px;
  min-width: 300px;
  margin: 0px 5px 0px 5px;
  position: relative;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 10px;
  overflow: hidden;
  flex: 1;
}

.documentNameLabel {
  margin: 0;
}

.targetLanguageContainer {
  display: flex;
}

.languageLabel {
  margin: 0;
  margin-right: 5px;
  flex: 1;
}

.correctedLabel {
  margin: 0;
  color: $secondary;
}

.downloadLinkContainer {
  position: absolute;
  bottom: 0px;
  right: 5px;
  p {
    margin: 0;
  }
}

.downloadAllLink {
  cursor: pointer;
  margin: 0;
  display: inline-block;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  font-size: 15px;
  margin-top: 20px;
  margin-right: 50px;
}

.downloadAllLink:hover {
  cursor: pointer;
  color: $secondary;
}

.downloadAllLinkDisabled {
  margin: 0;
  display: inline-block;
  opacity: 0.7;
  margin-right: 50px;
  font-size: 15px;
  margin-top: 20px;
}

.downloadAllLinkDisabled:hover {
  color: $primary !important;
}

.deleteAllOrderDocumentsLink {
  cursor: pointer;
  margin: 0;
  display: inline-block;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  > p {
    color: $error;
  }
  margin-right: 50px;
}

.deleteAllOrderDocumentsLink:hover {
  cursor: pointer;
  opacity: 0.7;
  > p {
    color: $error;
  }
}

.deleteAllOrderDocumentsLinkDisabled {
  margin: 0;
  display: inline-block;
  opacity: 0.7;
}

.deleteAllOrderDocumentsLinkDisabled:hover {
  color: $error !important;
}

.updateTranslationLink {
  cursor: pointer;
  margin: 0;
  display: inline-block;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  font-size: 15px;
  margin-top: 20px;
}

.updateTranslationLink:hover {
  cursor: pointer;
  color: $secondary;
}

.updateTranslationLinkDisabled {
  margin: 0;
  display: inline-block;
  opacity: 0.7;
  margin-right: 50px;
  font-size: 15px;
  margin-top: 20px;
}

.updateTranslationLinkDisabled:hover {
  color: $primary !important;
}

.footerContainer {
  display: flex;
}

.footerLabel {
  margin: 0;
}

.pushRight {
  margin-left: auto;
}

.noMargin {
  margin: 0;
}

.disabled {
  opacity: 0.5;
}

.filterContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.checkBoxContainer {
  margin-left: 10px;
  display: flex;
}

.checkBox {
  margin-right: 10px;
}

.checkBox:last-child {
  margin-right: 0;
}

.hidden {
  visibility: hidden;
}

.marginBottom {
  margin-bottom: 30px;
}

.allDocumentsDeletedLabel {
  color: $error;
  margin: 0;
}

.modalButtonContainer {
  display: flex;
  margin-top: 30px;
}

.modalDeleteAllOrderDocumentsCancelButton {
  margin-left: 0;
  margin-right: auto;
}

.modalDeleteAllOrderDocumentsCancelButton:focus {
  outline: none;
}

.modalDeleteAllOrderDocumentsButton {
  margin-right: 0;
  margin-left: auto;
  background-color: $error;
}

.modalDeleteAllOrderDocumentsButton:focus {
  outline: none;
}

.updateTranslationModal {
  max-height: 100% !important;
  max-width: 500px !important;
  width: 100%;
  padding: 0 20px;
}

.updateTranslationModalBackdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
}

.updateTranslationModalBackdropEnabled {
  z-index: 1000 !important;
}

.updateTranslationModalLoader {
  opacity: 0;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.updateTranslationModalLoaderEnabled {
  opacity: 1;
}

.updateTranslationModalContent {
  opacity: 1;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.updateTranslationModalContentDimmed {
  opacity: 0.5;
}

.updateTranslationFileLabel {
  margin-bottom: 5px;
}

.updateTranslationFileOriginalFileSelect {
  margin-bottom: 20px;
}

.modalFooterContainer {
  display: flex;
  flex-direction: column;
}

.browseFileContainer {
  display: flex;
  align-items: baseline;
  margin-bottom: 20px;
  margin-top: 10px;
}

.browseButton {
  padding: 9px;
  margin-right: 10px;
}

.updateTranslationFile {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 0;
  margin-left: 5px;
}

.updateTranslationFileModalButtonContainer {
  display: flex;
  margin-top: 10px;
}

.updateTranslationFileAddButton {
  margin-left: auto;
}

@media only screen and (min-width: 0) and (max-width: 500px) {
  .translationContainer {
    max-width: 200px;
    min-width: 200px;
  }

  .filterContainer {
    h6 {
      font-size: 10px;
    }
    p {
      font-size: 10px;
    }
  }

  .hidden {
    display: none;
  }
}

@media only screen and (min-width: 0) and (max-width: 400px) {
  .documentNameLabel {
    font-size: 10px;
    line-height: 13px;
  }

  .languageLabel {
    font-size: 10px;
    line-height: 13px;
  }

  .downloadLink {
    font-size: 10px;
  }
}
