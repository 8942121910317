@import 'assets/styles.scss';

.root {
  margin-left: 10%;
  margin-right: 10%;
}

.header {
  margin-top: 0;
  letter-spacing: 4px;
  color: $primary;
  text-align: center;
  margin-bottom: 10px;
}

.header:after {
  color: $secondary;
  content: '.';
  font-size: 34px;
}

.paragraph {
  margin: 5px;
  text-align: center;
}

.marginTop {
  margin-top: 20px;
}
