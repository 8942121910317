@import 'assets/styles.scss';

:export {
  whiteColor: $white;
  grayBrightColor: $gray-bright;
}

.root {
  border-width: 1px;
  border-style: solid;
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}

.padding {
  padding: 25px;
}

.border {
  border-width: 1px;
  border-style: solid;
}

.borderBottom {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.fullWidth {
  max-width: 100%;
}

@media only screen and (min-width: 0) and (max-width: 400px) {
  .padding {
    padding: 10px;
  }
}
