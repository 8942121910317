@import 'assets/styles.scss';

.root {
  width: 141px;
  height: 54px;
}

.defaultLogo {
  background-image: url('logo-black.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.secondaryLogo {
  background-image: url('logo-white.png');
  background-size: contain;
  background-repeat: no-repeat;
}
