@import 'assets/styles.scss';

.root {
  display: flex;
  align-items: center;
}

.disabled {
  opacity: 0.7;
  outline: 0;
}

.disabled:hover {
  opacity: 0.7;
  cursor: default;
}

.checkBox {
  width: 20px !important;
  height: 20px !important;
  color: $gray-dark;
}

.label {
  margin-left: 5px;
}
