@import 'assets/styles.scss';

.root {
}

.navbar {
}

.subPageContainer {
  padding: 80px 20px 20px 20px;
  text-align: center;
  margin: 0 auto;
  max-width: 65%;
  width: 100%;
  height: calc(100% - 120px);
}

.fullWidth {
  max-width: 100%;
  box-sizing: border-box;
}

@media only screen and (min-width: 0) and (max-width: 650px) {
  .subPageContainer {
    padding: 50px 10px 20px 10px;
    max-width: 95%;
  }
}
